
@import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');

.main-container{
  display: flex;
  flex-direction: column;
  flex-wrap : nowrap;
  max-height:100vh;
  background:white;
  border: 10px solid black;
  margin: 0px 10px 10px 10px;
  overflow: auto;
}

.lane-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-height: 350px;
  min-height: 320px;
  background: white;
  border: 3px solid black;
  overflow-y: auto;
}


.tags-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap : nowrap;
  opacity: .9;
  margin-left: 10px;
}


.wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap : nowrap;
}

.row-label{
  position: absolute;
  width:fit-content;
  height: fit-content;
  solid-opacity:50%;
  font-weight:bold;
  color: #112756;
  background-color: #ccd3e1;
  border: 1px solid #339aa4;
  border-radius: 10px;
}

.addnotes {
top:12%;
left:5%;
z-index:101;
}

.notes{
  display: flex;
  flex-flow: row wrap;
  width: inherit;
  padding-top: 5px;
}
.grid-item1 { grid-area: header; }
.grid-item2 { grid-area: left; }
.grid-item3 { grid-area: right; }

.recipeCard {
  display: grid;
  grid-template-areas:
    'header header'
    'left right';
  grid-gap: 1px;
  background-color: #92b0e7;
  font-family: 'Playball', cursive;
  margin: 15px 0px 0px 2px;
  width:250px;
  height: 290px;
  border: 1px solid #92b0e7;
}
.part0 {
  background-color: #92b0e7;
  border: 1px solid #dee7f8;
  width: 240px;
  height: 50px;
  margin: 5px 0px 0px 5px;
}

.part1 {
  background-color: #c3ecb9;
  border: 1px solid #dee7f8;
  width: 100px;
  height: 200px;
  margin: 5px 0px 0px 5px;
}
.part2 {
  background-color: #c3ecb9;
  border:1px solid #dee7f8;
  width: 140px;
  height: 200px;
  margin-top: 5px;
}

textarea:focus {
  border: #f5c0c091;
}