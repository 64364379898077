@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;

  }

  .Login form {
    margin: 0 auto;
    max-width:320px;
    
  }

  .forgot a {
    text-align: center;
    color: unset;
  }
}
