
.deleteboard {
    position:relative;
    background-color: #f5c0c091;
    float: right;
    margin-top: -30px;
    margin-right: 30px;
    width:15px;
    height:20px;
    z-index: 200;
}

.select-type{
    width:100px;
    height: 20px;
}



