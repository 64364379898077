.main-container{
  display: flex;
  flex-direction: row;
  flex-wrap : nowrap;
  min-height:100vh;
  background:white;
  border: 10px solid black;
  margin: 0px 10px 10px 10px;
  overflow: auto;
}

.user-stats{
  width:120px;
  solid-opacity:50%;
  color: #112756;
  background-color: #ccd3e1;
  margin-left:10px;
  border: 1px solid #339aa4;
  border-radius: 10px;
}

.share{
  solid-opacity:30%;
  background-color: #9fabc2;
  border: 1px #339aa4;
  border-radius: 10px;
  border: 2px solid #9fabc2;
}

.addnotes {
  display: inline-block;
  display: -moz-inline-box;
top:12%;
left:5%;
z-index:101;
}

.note {
  width: 140px;
  height: 140px;
  z-index: 101;
  margin:5px;
}

.note2 {
  float:left;
  padding-bottom: 38px;
}

.sticky {
width: 100%;
height: 100%;
draggable:true;
transform: rotate(0.5deg);
}


.sticky2 {
background-color: #CBFAFF;
}


.pin {
  float:left;
  margin-top: 9px;
width:12px;
height:12px;
}

.pin:hover {
width:14px;
height:14px;
}


.delete{
  float: right;
  margin-top: 11px;
  width:10px;
  height:10px;
}

.delete:hover {
  width:13px;
  height:13px;
}

.delete:active {
  width:8px;
  height:8px;
}

textarea:focus {
  border: #f5c0c091;
}