@import url(https://fonts.googleapis.com/css2?family=Playball&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playball&display=swap);


html{
  height:100%;
}

body{
  height:100%;
  background-image: url(/static/media/pbkground.7bb3bb03.jpg);
  background-position: 0px 0px;
  background-size: 100% 100%;
  margin-top:0px;
  background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
    margin-bottom:0px;
}
.text-block{
  position: absolute;
  text-align: center;
  top: 8%;
  left: 22%;
  right: 20%;

}

.text-font{
  font-family: 'Oxygen', sans-serif;
  font-size:20px;
}

.text-block2{

  position: absolute;
  text-align:left;
top:65%;
left:30%;
}
.text-font2{

  font-family: 'Oxygen', sans-serif;
  font-size:13px;

}
.text-color{
  color:#33b6c5;
}

.top-bar{
  background-color:rgba(0, 0, 0, 0);
  color:#d7efc7;
}
.navbar{
  color:#f1e2d8 !important;

}

.navbar-nav{
  position: absolute;
  right: 0;
  z-index: 120;
}

.navbar-default .navbar-brand:focus, .navbar-default .navbar-brand:hover
{
  color:#f1e2d8 !important;
}


.navbar .navbar-brand{
  color:#f1e2d8 !important;
}

.navbar .navbar-nav li a{
  color : #f1e2d8 !important;
}

.navbar .navbar-nav li a:hover{
  color : black !important;
}

.navbar .navbar-nav li.active{
  color : black !important;
}
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
  color: #e0c5b5 !important;
    background-color: #a76f6f;

}

.btn-default , .dropdown-menu {
  color : #f1e2d8 !important;
  background-color: inherit;
  border-color: transparent;
  margin-top:6.5px;
}

.btn-default:hover , .dropdown-menu:hover {
  color : black !important;
  background-color: inherit;
  border-color: transparent;
  margin-top:6.5px;
}
.dropdown-menu>.active>a, .dropdown-menu>.active>a:focus, .dropdown-menu>.active>a:hover {

  color: #444343;
  background-color: #99505d;
  opacity: 0.5;
}

.Home .lander {
  position:absolute;
  height:80%;
width:100%;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}

.Home .lander p {
  color: #2b802d;;
  font-size:25px;
}

.Home .lander .move-right{

}

.Home {
    flex: 1 1;
    width: 100%;
    height: 100%;
  }


.webImage {
  float:none;
  width:160px;
  height:100px;
}

.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  margin-left: 10px;
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;

  }

  .Login form {
    margin: 0 auto;
    max-width:320px;
    
  }

  .forgot a {
    text-align: center;
    color: unset;
  }
}

.move-right {
  position:absolute;
  top:28%;
  left:30%;
}

@media all and (min-width:480px) {
  .Signup {
    padding:60px 0;
  }

  .Signup form {
    margin: 0 auto;
    max-width: 320px;
  }

  .Signup form span.help-block {
    font-size: 12px;
    padding-bottom: 10px;
    color: #999;
  }
}

.NotFound {
  padding-top: 100px;
  text-align: center;
}


.deleteboard {
    position:relative;
    background-color: #f5c0c091;
    float: right;
    margin-top: -30px;
    margin-right: 30px;
    width:15px;
    height:20px;
    z-index: 200;
}

.select-type{
    width:100px;
    height: 20px;
}




.main-container{
  display: flex;
  flex-direction: row;
  flex-wrap : nowrap;
  min-height:100vh;
  background:white;
  border: 10px solid black;
  margin: 0px 10px 10px 10px;
  overflow: auto;
}

.user-stats{
  width:120px;
  solid-opacity:50%;
  color: #112756;
  background-color: #ccd3e1;
  margin-left:10px;
  border: 1px solid #339aa4;
  border-radius: 10px;
}

.share{
  solid-opacity:30%;
  background-color: #9fabc2;
  border: 1px #339aa4;
  border-radius: 10px;
  border: 2px solid #9fabc2;
}

.addnotes {
  display: inline-block;
  display: -moz-inline-box;
top:12%;
left:5%;
z-index:101;
}

.note {
  width: 140px;
  height: 140px;
  z-index: 101;
  margin:5px;
}

.note2 {
  float:left;
  padding-bottom: 38px;
}

.sticky {
width: 100%;
height: 100%;
draggable:true;
-webkit-transform: rotate(0.5deg);
        transform: rotate(0.5deg);
}


.sticky2 {
background-color: #CBFAFF;
}


.pin {
  float:left;
  margin-top: 9px;
width:12px;
height:12px;
}

.pin:hover {
width:14px;
height:14px;
}


.delete{
  float: right;
  margin-top: 11px;
  width:10px;
  height:10px;
}

.delete:hover {
  width:13px;
  height:13px;
}

.delete:active {
  width:8px;
  height:8px;
}

textarea:focus {
  border: #f5c0c091;
}

.main-container{
  display: flex;
  flex-direction: column;
  flex-wrap : nowrap;
  max-height:100vh;
  background:white;
  border: 10px solid black;
  margin: 0px 10px 10px 10px;
  overflow: auto;
}

.lane-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-height: 350px;
  min-height: 320px;
  background: white;
  border: 3px solid black;
  overflow-y: auto;
}

.user-stats{
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  opacity:.8;
  font-weight:bold;
  color: #112756;
  border-radius: 10px;
  margin: 0px;
}

.tags-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap : nowrap;
  opacity: .9;
  margin-left: 10px;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap : nowrap;
}

.addnotes {
top:12%;
left:5%;
z-index:101;
}

.grid-item1 { grid-area: header; }
.grid-item2 { grid-area: left; }
.grid-item3 { grid-area: right; }

.mprecipeCard {
  display: grid;
  grid-template-areas:
    'header header'
    'left right';
  grid-gap: 1px;
  background-color: #a9bfef;
  font-family: 'Playball', cursive;
  margin: 0px 0px 0px 2px;
  width:250px;
  height: 267px;
  border: 1px solid #dee7f8;
}


.mppart0 {
  background-color: #a9bfef;
  border: 1px solid #a9bfef;
  width: 220px;
  height: 50px;
  margin: 3px 0px 0px 3px;
}

.mppart1 {
  background-color: #caf7fd;
  border: 1px solid #dee7f8;
  width: 100px;
  height: 200px;
  margin: 3px 0px 0px 3px;
}
.mppart2 {
  background-color: #caf7fd;
  border:1px solid #dee7f8;
  width: 140px;
  height: 200px;
  margin-top: 3px;
}

textarea:focus {
  border: #f5c0c091;
}

.main-container{
  display: flex;
  flex-direction: column;
  flex-wrap : nowrap;
  max-height:100vh;
  background:white;
  border: 10px solid black;
  margin: 0px 10px 10px 10px;
  overflow: auto;
}

.lane-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-height: 350px;
  min-height: 320px;
  background: white;
  border: 3px solid black;
  overflow-y: auto;
}


.tags-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap : nowrap;
  opacity: .9;
  margin-left: 10px;
}


.wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap : nowrap;
}

.row-label{
  position: absolute;
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  solid-opacity:50%;
  font-weight:bold;
  color: #112756;
  background-color: #ccd3e1;
  border: 1px solid #339aa4;
  border-radius: 10px;
}

.addnotes {
top:12%;
left:5%;
z-index:101;
}

.notes{
  display: flex;
  flex-flow: row wrap;
  width: inherit;
  padding-top: 5px;
}
.grid-item1 { grid-area: header; }
.grid-item2 { grid-area: left; }
.grid-item3 { grid-area: right; }

.recipeCard {
  display: grid;
  grid-template-areas:
    'header header'
    'left right';
  grid-gap: 1px;
  background-color: #92b0e7;
  font-family: 'Playball', cursive;
  margin: 15px 0px 0px 2px;
  width:250px;
  height: 290px;
  border: 1px solid #92b0e7;
}
.part0 {
  background-color: #92b0e7;
  border: 1px solid #dee7f8;
  width: 240px;
  height: 50px;
  margin: 5px 0px 0px 5px;
}

.part1 {
  background-color: #c3ecb9;
  border: 1px solid #dee7f8;
  width: 100px;
  height: 200px;
  margin: 5px 0px 0px 5px;
}
.part2 {
  background-color: #c3ecb9;
  border:1px solid #dee7f8;
  width: 140px;
  height: 200px;
  margin-top: 5px;
}

textarea:focus {
  border: #f5c0c091;
}
.move-right {
  position:absolute;
  top:28%;
  left:30%;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}

