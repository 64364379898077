.Home .lander {
  position:absolute;
  height:80%;
width:100%;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}

.Home .lander p {
  color: #2b802d;;
  font-size:25px;
}

.Home .lander .move-right{

}

.Home {
    flex: 1;
    width: 100%;
    height: 100%;
  }


.webImage {
  float:none;
  width:160px;
  height:100px;
}
