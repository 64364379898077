body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}
